<template>
  <div class="">
    Customers Table
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'Customers'
}
</script>
